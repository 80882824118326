/**
 * @name: name of this sections
 * @description: description of this component
 */

import Image from '../image'
import { Directus_Files, Pages } from '@/ts/graphqlschema'
import SearchBox from '../sections/search-box'
import { getCMSImageUrl } from '@/utils/image-utils/get-cms-image'
import { ISectionGallery } from '@/ts/sections'

interface IHeroImageSearch {
  pageData: Pages
  section_gallery: ISectionGallery | null
}

const HeroImageSearch = (props: IHeroImageSearch) => {
  const { pageData, section_gallery } = props

  let lists: Directus_Files[] | undefined
  if (section_gallery) {
    lists = section_gallery.item.images?.map(item => ({
      ...item.directus_files_id,
    }))
  }

  let randomImages = null

  if (lists) {
    randomImages = lists[Math.floor(Math.random() * lists.length)]
  }
  const imageUrl =
    getCMSImageUrl(randomImages, 'fourk') || '/images/heading-image.jpeg'
  const caption = (randomImages && randomImages.title) || 'MrBiker Saigon'

  return (
    <section id="hero-section" className="heading-image h-[50vh] md:h-[80vh]">
      <div className={'transition-opacity duration-500 ease-in-out -z-10'}>
        <div className="absolute w-full h-full">
          <Image
            id="hero-video"
            className="w-full h-full h-[50vh] md:h-screen z-0 object-contain md:object-cover"
            src={imageUrl}
            title={caption}
            alt={caption}
            priority
            fill
            sizes="100vw"
            style={{
              objectFit: 'cover',
            }}
          />
        </div>
      </div>
      <div className="absolute top-0 left-0 h-full w-full bg-black bg-opacity-50" />
      <div className="relative w-full h-full wrap-content">
        <div className="absolute top-1/2 z-10 w-full xl:w-3/4 transform -translate-y-1/2 xl:left-8 left-0 px-8 xl:px-0">
          <h3 className="font-alegreya text-primary text-4xl md:text-5xl font-normal italic">
            Mr Biker Saigon
          </h3>
          <h1 className="text-white leading-tight font-alegreya uppercase py-6 text-3xl md:text-4xl xl:text-5xl">
            {pageData.name}
          </h1>
          {/*<p className="text-white mb-5">{pageData.description}</p>*/}
          <SearchBox />
          {/*<div className="mt-5 max-w-96 flex flex-wrap gap-3">*/}
          {/*  <ProductTags customStyles="button--primary__tag-lighter cursor-pointer" />*/}
          {/*</div>*/}
        </div>
      </div>
      <div className="absolute bottom-0 left-0 h-8 md:h-32 w-full">
        <Image
          src="/images/white_bottom_wave_01.png"
          alt="white_bottom_wave_01.png"
          priority
          fill
          sizes="100vw"
        />
      </div>
    </section>
  )
}

export default HeroImageSearch

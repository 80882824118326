/**
 * @name: Toggle currency state
 * @description: Using to change the currency
 */

import { observer } from 'mobx-react-lite'
import { ChangeEventHandler, FC, memo } from 'react'
import useCookie from 'src/hook/useCookies'
import { useCurrencyStore, useRootStore } from 'src/providers/GlobalProvider'

interface IToggleCurrencies {}

const ToggleCurrencies: FC<IToggleCurrencies> = observer(() => {
  const { currencies } = useCurrencyStore()
  const { currencyStore } = useRootStore()
  const [data, updateCookies] = useCookie('currency')

  const onChangeCurrencies: ChangeEventHandler<HTMLSelectElement> = e => {
    currencyStore.setMainCurrency(e.target.value)
    updateCookies(e.target.value, 3)
  }
  return (
    <select
      className="w-20 font-semibold font-wix-display bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:accent-yellow-600 focus:border-yellow-600 block p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-yellow-600 dark:focus:border-yellow-700"
      onChange={onChangeCurrencies}
      value={data}
    >
      {currencies?.map(currency => (
        <option
          className="font-wix-display"
          value={currency.name}
          key={`currency-${currency.id}`}
        >
          {currency.name}
        </option>
      ))}
    </select>
  )
})

export default memo(ToggleCurrencies)

import { SEARCH_PAGE_PATH } from '@/data/navigation'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { FC, KeyboardEventHandler, memo, useCallback, useRef } from 'react'

const ArrowNarrowRight = dynamic(
  () => import('@/components/svgs/arrow-narrow-right')
)
const Search = dynamic(() => import('@/components/svgs/search'))

type TSearchBoxProps = {
  placeHolderColor?: string
}

const SearchBox: FC<TSearchBoxProps> = () => {
  const router = useRouter()
  const searchInputRef = useRef<HTMLInputElement>(null)

  const handleSearch = useCallback(() => {
    router.push(
      {
        pathname: SEARCH_PAGE_PATH,
        query: {
          q: searchInputRef?.current?.value,
        },
      },
      undefined,
      { scroll: false }
    )
  }, [router])
  const handleKeyboardEvent: KeyboardEventHandler<HTMLInputElement> =
    useCallback(
      e => {
        if (e.key === 'Enter') {
          searchInputRef?.current?.value && handleSearch()
        }
      },
      [handleSearch]
    )
  return (
    <div className="flex gap-3 md:gap-5">
      <div className="search-input__container">
        <div className="search-input__icon h-5 w-5">
          <Search />
        </div>
        <input
          defaultValue={router.query.q}
          ref={searchInputRef}
          placeholder="Astonishing anywhere? Saigon, Ha Giang, North Vietnam?"
          className={`search-input`}
          onKeyPress={handleKeyboardEvent}
        />
      </div>
      <button
        className="button--primary flex items-center gap-2 box-utility-lg"
        onClick={() => (searchInputRef?.current?.value ? handleSearch() : null)}
      >
        <div className="h-6 w-6 hidden md:block">
          <ArrowNarrowRight />
        </div>
        Search
      </button>
    </div>
  )
}

export default memo(SearchBox)

import { FC } from 'react'
import GrapeParser, { HTMLBuilderParserProps } from '.'

const DataFetcher: FC<HTMLBuilderParserProps> = props => {
  const { className, component, currentItem, globalData, extra } = props
  const collection = component.dataCollection
  const params = {
    fields: component.fields,
    filter: component.filter ? JSON.parse(component.filter) : undefined,
    search: component.search,
    limit: component.limit,
    offset: component.offset,
    sort: component.sort,
    meta: component.meta,
  }
  const data = globalData[`${collection}-${JSON.stringify(params)}`]

  return (
    <div className={className} {...component.attributes}>
      {component.components?.map((component, index) => {
        return (
          <GrapeParser
            key={index}
            component={component}
            currentItem={currentItem}
            globalData={globalData}
            data={data}
            extra={extra}
          />
        )
      })}
    </div>
  )
}

export default DataFetcher

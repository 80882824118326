import { FC } from 'react'
import { HTMLBuilderParserProps } from '.'
import { getValueByField } from './utils'
import { useCurrencyStore } from '../../providers/GlobalProvider'
import renderPriceByCurrency from '@/utils/renderPriceByCurrency'
import { observer } from 'mobx-react-lite'

const RenderPrice: FC<HTMLBuilderParserProps> = observer(props => {
  const { className, component, data } = props
  const { field } = component

  const price = getValueByField(data, field)
  const { mainCurrency } = useCurrencyStore()
  const priceByCurrency = renderPriceByCurrency(price, mainCurrency)

  if (!price) return

  return (
    <div className={className} {...component.attributes}>
      {priceByCurrency}
    </div>
  )
})

export default RenderPrice

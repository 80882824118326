import { FC } from 'react'
import GrapeParser, { HTMLBuilderParserProps } from '.'
import { getValueByField } from './utils'

const DataRepeater: FC<HTMLBuilderParserProps> = props => {
  const { className, component, currentItem, globalData, data, extra } = props
  const { field } = component

  const value: Array<any> | null = getValueByField(data, field)
  const firstChildComponent = component?.components?.[0]

  if (!firstChildComponent) return null

  return (
    <div className={className} {...component.attributes}>
      {value?.map((item, index) => (
        <GrapeParser
          key={index}
          component={{
            ...firstChildComponent,
            field: `[${index}]`,
          }}
          currentItem={currentItem}
          globalData={globalData}
          data={value}
          extra={extra}
        />
      ))}
    </div>
  )
}

export default DataRepeater

import { Currencies } from '@/ts/graphqlschema'
import { isEmpty } from 'lodash'

/**
 *
 * @param {number | undefined} price a number product price
 * @param {Currencies} currency a currency for calculating price to render & the symbol
 * @returns a string representation price by currency
 */
export default function renderPriceByCurrency(
  price: number | undefined,
  currency: Currencies
): string {
  if (!price || isEmpty(currency)) return '--'

  const { current_rate, prefix, suffix } = currency
  const renderPrefix = prefix || ''
  const renderSuffix = suffix || ''

  return `${renderPrefix}${new Intl.NumberFormat('en-US').format(
    Math.floor(price / current_rate)
  )}${renderSuffix}`
}

import MetaHeader, { SOCIAL_ACCOUNTS } from '@/components/header/metaheader'
import {
  CreateCustomerMutation,
  CreateOrderMutation,
  CreateOrderMutationVariables,
  Products,
  Product_Variants,
} from '@/ts/graphqlschema'
import { fetchApi } from '@/utils/api'
import renderPriceByCurrency from '@/utils/renderPriceByCurrency'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import { FC, MouseEventHandler, useCallback, useMemo } from 'react'
import { useCurrencyStore, useRootStore } from 'src/providers/GlobalProvider'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import moment from 'moment'

export interface SideDetailProps {
  post?: Products
}

const SideDetail: FC<SideDetailProps> = observer(props => {
  const {
    post: { name, price, product_variants },
  } = props

  const router = useRouter()

  const { mainCurrency } = useCurrencyStore()
  const { modalStore } = useRootStore()

  const isAbleToBookTour = useMemo<boolean>(() => {
    return !!product_variants?.length
  }, [product_variants?.length])

  const handleBookTour = useCallback<
    (variant_id: Product_Variants['id']) => void
  >(
    async variant_id => {
      let initCustomer: CreateCustomerMutation
      try {
        initCustomer = await fetchApi<CreateCustomerMutation>(
          '/api/customers',
          undefined,
          'POST'
        )
      } catch (e) {}
      if (isEmpty(initCustomer)) return

      let order: CreateOrderMutation
      const customerId = initCustomer.create_customers_item?.id
      try {
        order = await fetchApi<
          CreateOrderMutation,
          CreateOrderMutationVariables['input']
        >(
          '/api/orders',
          {
            id: '1',
            customer: {
              id: customerId,
            },
            items: [
              {
                customer: {
                  id: customerId,
                },
                quantity: 1,
                variant: Number(variant_id),
              },
            ],
            meta: {
              extra_information: '',
            },
          },
          'POST'
        )
      } catch (error) {}

      if (isEmpty(order)) return

      router.push({
        pathname: `/orders/${order.create_orders_item?.id}`,
        search: `?cid=${customerId}`,
      })
      modalStore.setIsOpenModal(false)
    },
    [modalStore, router]
  )

  const priceToRender = useMemo<string>(() => {
    if (!product_variants?.length)
      return renderPriceByCurrency(price, mainCurrency)
    if (!price) return 'Contact us for pricing'
    return renderPriceByCurrency(product_variants[0].price, mainCurrency)
  }, [mainCurrency, price, product_variants])

  const handleClickBookSideBar: MouseEventHandler<HTMLAnchorElement> =
    useCallback(
      e => {
        e.preventDefault()
        modalStore.setFullModalData(
          {
            body: (
              <>
                <Table className="font-wix-display md:text-base w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <Thead className="uppercase">
                    <Tr>
                      {/*<Th width="45%">Start</Th>*/}
                      <Th width="20%">Trip Start</Th>
                      <Th width="5%"></Th>
                      <Th width="20%">Trip End</Th>
                      {/*<Th>Date</Th>*/}
                      <Th width="40%" className="text-left">
                        Price
                      </Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody className="text-dark font-semibold">
                    {product_variants?.map(pv => (
                      <>
                        <Tr className="rounded mb-4 border-b group hover:bg-gray-100 cursor-pointer">
                          {/*<Td>{pv.name}</Td>*/}
                          <Td className="py-5">
                            <div className="text-gray-900">
                              {moment(pv.start_date).format('ll')}
                            </div>
                          </Td>
                          <Td>
                            <svg
                              className="w-6"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5 16H27"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M18 7L27 16L18 25"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Td>
                          <Td>
                            <div className="text-gray-900">
                              {moment(pv.end_date).format('ll')}
                            </div>
                          </Td>
                          {/*<Td className="py-2">*/}
                          {/*  {renderDate(pv.start_date, pv.end_date)}*/}
                          {/*</Td>*/}
                          <Td className="text-left py-2 text-xl font-bold text-">
                            {renderPriceByCurrency(pv.price, mainCurrency)}
                          </Td>
                          <Td className="text-right py-2">
                            <button
                              key={`variant-id-${pv.id}`}
                              className="button--primary__small text-center cursor-pointer"
                              v-id={pv.id}
                              onClick={e => {
                                handleBookTour(
                                  e.currentTarget.getAttribute('v-id')
                                )
                              }}
                            >
                              Book this date
                            </button>
                          </Td>
                        </Tr>
                      </>
                    ))}
                  </Tbody>
                </Table>
              </>
            ),
            title: 'Please choose a tour to book',
          },
          true
        )
      },
      [handleBookTour, mainCurrency, modalStore, product_variants]
    )

  return (
    <>
      <div
        className="w-full xl:p-12 p-4 md:p-8 border border-gray-1 rounded-2xl bg-white font-sans"
        id="aside-content"
      >
        <p className="mb-4">
          <strong className="text-xl italic text-gray-400">{name}</strong>
        </p>
        {price && (
          <>
            <label className="text-xs italic block">from</label>
            <h4 className="text-4xl font-sans font-semibold mb-3">
              {priceToRender}
            </h4>
          </>
        )}
        <div className="fixed bottom-0 left-0 w-full py-8 shadow-lg bg-lighter text-center xl:relative xl:py-0 xl:shadow-none xl:bg-transparent xl:text-left drop-shadow-2xl z-30">
          <a
            className="button--primary mb-4 text-center cursor-pointer w-full"
            href={isAbleToBookTour ? '#' : 'mailto:info@mrbikersaigon.com'}
            onClick={isAbleToBookTour ? handleClickBookSideBar : null}
          >
            Book this tour now
          </a>
        </div>
        <hr className="my-4" />
        <label className="text-xs italic block">Contact Us</label>
        <p className="mb-2">
          <strong>
            <a
              href="mailto:tours@mrbikersaigon.com"
              className="text-xl hover:text-primaryDark"
            >
              tours@mrbikersaigon.com
            </a>
          </strong>
        </p>
        <p className="mb-4">
          <strong>
            <a
              href="https://wa.me/84349417856"
              className="text-xl hover:text-primaryDark"
            >
              +84 34 941 7856 (WhatsApp)
            </a>
          </strong>
        </p>
        <label className="text-xs italic block">Share</label>
        <ul className="flex gap-3">
          <MetaHeader metaHeaderItems={SOCIAL_ACCOUNTS} />
        </ul>
      </div>
    </>
  )
})

export default SideDetail

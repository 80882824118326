import { Product_Categories } from '@/ts/graphqlschema'
import { isEmpty } from 'lodash'
import { useRouter } from 'next/router'
import { ChangeEventHandler, FC, useCallback } from 'react'

interface ICategoriesTree {
  category: Product_Categories
  enabled?: boolean
}

const CategoriesTree: FC<ICategoriesTree> = ({
  category: { name, id, slug, children },
  enabled,
}) => {
  const router = useRouter()

  const handleCheck: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      const routerCategoryQuery = router.query?.categories?.toString()
      let categoryParams: string[] = routerCategoryQuery
        ? decodeURI(routerCategoryQuery).split(',')
        : []

      if (e.target.checked) {
        categoryParams.push(e.target.value)
      } else {
        categoryParams = categoryParams.filter(slug => slug !== e.target.value)
      }

      router.push({
        pathname: '/shop',
        query: {
          categories: categoryParams.toString(),
        },
      })
    },
    [router]
  )
  return (
    <div>
      <input
        type="checkbox"
        id={`${name}-${id}`}
        name={`${name}-${id}`}
        value={slug}
        onChange={handleCheck}
        checked={enabled}
      />
      <label htmlFor={`${name}-${id}`} className="ml-2">
        {name}
      </label>
      {!isEmpty(children) && (
        <div className="ml-4">
          {children.map((cat: Product_Categories & { enabled?: boolean }) => (
            <CategoriesTree
              category={cat}
              key={`${cat.name}-${cat.id}`}
              enabled={cat.enabled}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default CategoriesTree

import {
  Get_Product_CategoriesQuery,
  Get_Product_CategoriesQueryVariables,
  Product_Categories,
} from '@/ts/graphqlschema'
import { fetchApi } from '@/utils/api'
import { useRouter } from 'next/router'
import React, { memo, useMemo } from 'react'
import useSWR, { useSWRConfig } from 'swr'
import CategoriesTree from './CategoriesTree'

interface CategoriesQuery {
  key: string
  query: Get_Product_CategoriesQueryVariables
}

const categoriesQuery: CategoriesQuery = {
  key: 'collections_query',
  query: {
    filter: {
      parent: {
        id: {
          _null: true,
        },
      },
    },
  },
}

const ShopSideBar = () => {
  const { cache } = useSWRConfig()
  const router = useRouter()

  const { data: categories } = useSWR(
    categoriesQuery.key,
    () =>
      !cache.get(categoriesQuery.key)?.data
        ? fetchApi<
            Get_Product_CategoriesQuery,
            Get_Product_CategoriesQueryVariables
          >('/api/categories')
        : null,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  const productCategories = useMemo(() => {
    if (!categories?.product_categories) return []

    const routerCategoryQuery = router.query?.categories?.toString()
    const categoryParams: Set<string> = new Set(
      routerCategoryQuery ? decodeURI(routerCategoryQuery).split(',') : []
    )

    const categoriesData = categories?.product_categories.map(category => {
      return {
        ...category,
        enabled: categoryParams.has(category.slug),
      }
    })

    return categoriesData
  }, [categories?.product_categories, router.query?.categories])

  return (
    <aside>
      {productCategories?.map(category => {
        return (
          <CategoriesTree
            category={category as unknown as Product_Categories}
            key={`${category.name}-${category.id}`}
            enabled={category.enabled}
          />
        )
      })}
    </aside>
  )
}

export default memo(ShopSideBar)

import { FC, useMemo } from 'react'
import ImageGallerySlider, {
  GallerySliderItem,
} from '../header/tour-gallery-slider'

type imageItem = {
  caption?: string
  thumbnail?: string
}

interface IRenderImageList {
  imageList: imageItem[]
}

const RenderImageList: FC<IRenderImageList> = ({ imageList }) => {
  const galleryMap = useMemo<GallerySliderItem[]>(
    () =>
      imageList?.map(({ caption, thumbnail }) => ({
        key: thumbnail,
        alt: caption,
        src: `${process.env.NEXT_PUBLIC_API_URL}/assets/${thumbnail}`,
      })),
    [imageList]
  )

  return <ImageGallerySlider galleryMap={galleryMap} />
}

export default RenderImageList

import { createElement, FC } from 'react'

interface ICustomText {
  marks?: {
    type: string
    attrs?: Record<string, any>
    [key: string]: any
  }[]
  text?: string
}

const MarksElementMapping: { [key: string]: string } = {
  bold: 'strong',
  underline: 'u',
  italic: 'em',
  link: 'a',
  strike: 's',
}

const CustomText: FC<ICustomText> = ({ text, marks }) => {
  if (!marks || marks.length === 0) return <>{text}</>
  const firstMarkType = marks[0]['type']
  let node = createElement(
    MarksElementMapping[firstMarkType],
    {
      href: firstMarkType === 'link' ? marks[0]?.attrs['href'] : undefined,
      target: firstMarkType === 'link' ? '_blank' : undefined,
    },
    text
  )
  for (let i = 1; i < marks.length; i++) {
    const markType = marks[i]['type']

    node = createElement(
      MarksElementMapping[marks[i]['type']],
      {
        href: markType === 'link' ? marks[i]?.attrs['href'] : undefined,
        target: markType === 'link' ? '_blank' : undefined,
      },
      node
    )
  }
  return node
}

export default CustomText

import { FC } from 'react'
import { HTMLBuilderParserProps } from '.'
import Link from 'next/link'
import { useForm, SubmitHandler } from 'react-hook-form'

interface InnerFormProps extends HTMLBuilderParserProps {
  register: any
}

const InnerForm: FC<InnerFormProps> = props => {
  const { component, register } = props
  const { type, classes, components, attributes, content, tagName } = component

  const className = (classes || [])
    .map(cls => (typeof cls === 'string' ? cls : cls.name))
    .filter(cls => !cls.startsWith('gjs-'))
    .join(' ')

  // convert attributes to camelCase
  const camelCaseAttributes: Record<string, any> = Object.keys(
    attributes || {}
  ).reduce((acc, key) => {
    let newKey = key
    if (
      !newKey.startsWith('x-') &&
      !newKey.startsWith('data-') &&
      !newKey.startsWith('aria-')
    ) {
      newKey = key.replace(/-([a-z])/g, g => g[1].toUpperCase())
    }
    if (newKey === 'for') {
      newKey = 'htmlFor'
    }
    return {
      ...acc,
      [newKey]: attributes[key],
    }
  }, {})

  const children = components?.map((component, index) => (
    <InnerForm key={index} component={component} register={register} />
  ))

  if (type === 'input' || type === 'checkbox' || type === 'radio') {
    return (
      <input
        className={className}
        {...camelCaseAttributes}
        checked={null}
        defaultChecked={camelCaseAttributes.checked}
        {...(camelCaseAttributes.name && register(camelCaseAttributes.name))}
      />
    )
  }

  if (type === 'textarea') {
    return (
      <textarea
        className={className}
        {...camelCaseAttributes}
        {...(camelCaseAttributes.name && register(camelCaseAttributes.name))}
      />
    )
  }

  if (type === 'select') {
    return (
      <select
        className={className}
        {...camelCaseAttributes}
        {...(camelCaseAttributes.name && register(camelCaseAttributes.name))}
      >
        {children}
      </select>
    )
  }

  if (type === 'option') {
    return (
      <option className={className} {...camelCaseAttributes}>
        {children}
      </option>
    )
  }

  if (type === 'button') {
    return (
      <button className={className} {...camelCaseAttributes}>
        {children}
      </button>
    )
  }

  if (type === 'label') {
    return (
      <label className={className} {...camelCaseAttributes}>
        {children}
      </label>
    )
  }

  if (type == 'textnode') {
    return <>{content}</>
  }

  if (type == 'link') {
    const href = attributes?.href
    if (href?.startsWith('/')) {
      return (
        <Link href={href} passHref>
          <a className={className} {...camelCaseAttributes}>
            {children}
          </a>
        </Link>
      )
    }
    return (
      <a className={className} {...camelCaseAttributes}>
        {children}
      </a>
    )
  }

  if (tagName === 'br') {
    return <br />
  }

  return (
    <div className={className} {...camelCaseAttributes}>
      {children}
    </div>
  )
}

const Form: FC<HTMLBuilderParserProps> = props => {
  const { className, component } = props
  const { register, handleSubmit } = useForm()
  // eslint-disable-next-line no-console
  const onSubmit: SubmitHandler<any> = data => console.log(data)

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      {component.components?.map((component, index) => {
        return (
          <InnerForm key={index} component={component} register={register} />
        )
      })}
    </form>
  )
}

export default Form

import { FC } from 'react'
import GrapeParser, { HTMLBuilderParserProps } from '.'
import { getValueByField } from './utils'

const DataContainer: FC<HTMLBuilderParserProps> = props => {
  const { className, component, currentItem, globalData, data, extra } = props
  const { field } = component

  const value = getValueByField(data, field)

  return (
    <div className={className} {...component.attributes}>
      {component.components?.map((component, index) => {
        return (
          <GrapeParser
            key={index}
            component={component}
            currentItem={currentItem}
            globalData={globalData}
            data={value}
            extra={extra}
          />
        )
      })}
    </div>
  )
}

export default DataContainer

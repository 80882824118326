import { FC } from 'react'
import { HTMLBuilderParserProps } from '.'
import { getValueByField } from './utils'
import Image from '../image'

const PlaceholderImage: FC<HTMLBuilderParserProps> = props => {
  const { className, component, data } = props
  const { field } = component

  let src = getValueByField(data, field)

  if (typeof src === 'object' && src !== null) {
    src = src.filename_disk
  }

  return (
    <Image
      className={className}
      {...component.attributes}
      src={`${process.env.NEXT_PUBLIC_API_URL}/assets/${src}`}
      alt={component.attributes?.alt}
      width={component.attributes?.width || '0'}
      height={component.attributes?.height || '0'}
      sizes={component.attributes?.sizes || '100vw'}
    />
  )
}

export default PlaceholderImage

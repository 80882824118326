import { FC } from 'react'
import GrapeParser, { HTMLBuilderParserProps } from '.'
import { getValueByField } from './utils'
import { parseExpression } from './data-transformer-operations'

const DataTransformer: FC<HTMLBuilderParserProps> = props => {
  const { className, component, currentItem, globalData, data, extra } = props
  const { field } = component

  const value = getValueByField(data, field)

  let transformedValue: Record<string, any>
  if (typeof value !== 'object' || value === null) {
    transformedValue = { value }
  } else {
    transformedValue = { ...value }
  }

  const fieldsRaw: string | null = component.fields
  if (fieldsRaw) {
    try {
      const fields = JSON.parse(fieldsRaw)

      Object.keys(fields).forEach(field => {
        const value = parseExpression(fields[field], transformedValue)
        transformedValue[field] = value
      })
    } catch (error) {
      /* empty */
    }
  }

  return (
    <div className={className} {...component.attributes}>
      {component.components?.map((component, index) => {
        return (
          <GrapeParser
            key={index}
            component={component}
            currentItem={currentItem}
            globalData={globalData}
            data={transformedValue}
            extra={extra}
          />
        )
      })}
    </div>
  )
}

export default DataTransformer

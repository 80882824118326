import { createElement, FC, useCallback } from 'react'
import DataFetcher from './data-fetcher'
import PlaceholderImage from './placeholder-image'
import DataContainer from './data-container'
import DataRepeater from './data-repeater'
import PlaceholderText from './placeholder-text'
import DataLink from './data-link'
import Image from '../image'
import Link from 'next/link'
import handleCustomComponent from './custom'
import DataCurrentItem from './data-current-item'
import DataTransformer from './data-transformer'
import ParserView from '@/components/ParserView'
import Form from './form'

export type HTMLBuilderComponent = {
  type?: string
  attributes?: Record<string, any>
  content?: string
  classes: Array<string | { name: string }>
  tagName?: string
  components?: HTMLBuilderComponent[]
  [key: string]: any
}
export interface HTMLBuilderParserProps {
  component: HTMLBuilderComponent
  currentItem?: any
  globalData?: any
  data?: any
  extra?: any
  className?: string
}

const GrapeParser: FC<HTMLBuilderParserProps> = props => {
  const { component, currentItem, globalData, extra } = props
  const { type, content, attributes, components, classes, tagName } = component
  const data = props.data

  const className = (classes || [])
    .map(cls => (typeof cls === 'string' ? cls : cls.name))
    .filter(cls => !cls.startsWith('gjs-'))
    .join(' ')

  // convert attributes to camelCase
  const camelCaseAttributes = Object.keys(attributes || {}).reduce(
    (acc, key) => {
      let newKey = key
      if (
        !newKey.startsWith('x-') &&
        !newKey.startsWith('data-') &&
        !newKey.startsWith('aria-')
      ) {
        newKey = key.replace(/-([a-z])/g, g => g[1].toUpperCase())
      }
      return {
        ...acc,
        [newKey]: attributes[key],
      }
    },
    {}
  )

  const renderItem = useCallback<
    (children: React.ReactNode) => string | JSX.Element
  >(
    children => {
      const customComponent = handleCustomComponent(props)
      if (customComponent) {
        return customComponent
      }

      if (type == 'tiptap') {
        const tiptapContent = component?.tiptapContent
        const contentInJson = tiptapContent && JSON.parse(tiptapContent)
        return <ParserView json={contentInJson.json} />
      }

      if (type == 'iframe') {
        return (
          <iframe
            className={className}
            id={attributes?.id}
            src={attributes?.src}
            loading={attributes?.loading}
            title={attributes?.title}
            width={attributes?.width}
            height={attributes?.height}
          />
        )
      }

      if (type == 'wrapper') {
        return (
          <div className="block" {...camelCaseAttributes}>
            {children}
          </div>
        )
      }
      if (type == 'textnode') {
        return content
      }
      if (type == 'image') {
        return (
          <Image
            className={className}
            {...component.attributes}
            src={attributes?.src}
            alt={attributes?.alt}
            width="0"
            height="0"
            sizes="100vw"
          />
        )
      }
      if (type == 'svg') {
        return (
          <svg className={className} {...camelCaseAttributes}>
            {children}
          </svg>
        )
      }

      if (type == 'svg-in') {
        return createElement(
          tagName,
          { className, ...camelCaseAttributes },
          'This is svg'
        )
      }

      if (type == 'link') {
        const href = attributes?.href
        if (href?.startsWith('/')) {
          return (
            <Link href={href} passHref>
              <a className={className} {...camelCaseAttributes}>
                {children}
              </a>
            </Link>
          )
        }
        return (
          <a className={className} {...camelCaseAttributes}>
            {children}
          </a>
        )
      }

      if (type == 'data-fetcher') {
        return (
          <DataFetcher
            className={className}
            component={component}
            globalData={globalData}
            data={data}
            extra={extra}
          />
        )
      }
      if (type == 'data-current-item') {
        return (
          <DataCurrentItem
            className={className}
            component={component}
            currentItem={currentItem}
            globalData={globalData}
            extra={extra}
          />
        )
      }
      if (type == 'data-repeater') {
        return (
          <DataRepeater
            className={className}
            component={component}
            globalData={globalData}
            data={data}
            extra={extra}
          />
        )
      }
      if (type == 'data-container') {
        return (
          <DataContainer
            className={className}
            component={component}
            globalData={globalData}
            data={data}
            extra={extra}
          />
        )
      }
      if (type == 'data-link') {
        return (
          <DataLink
            className={className}
            component={component}
            globalData={globalData}
            data={data}
            extra={extra}
          />
        )
      }
      if (type == 'data-transformer') {
        return (
          <DataTransformer
            className={className}
            component={component}
            globalData={globalData}
            data={data}
            extra={extra}
          />
        )
      }
      if (type == 'placeholder-text') {
        return (
          <PlaceholderText
            className={className}
            component={component}
            data={data}
          />
        )
      }
      if (type == 'placeholder-image') {
        return (
          <PlaceholderImage
            className={className}
            component={component}
            data={data}
          />
        )
      }

      if (type == 'video') {
        if (tagName == 'iframe') {
          return (
            <iframe
              className={className}
              src={component?.src}
              loading={attributes?.loading}
              title={attributes?.title}
              width={attributes?.width}
              height={attributes?.height}
              {...camelCaseAttributes}
            />
          )
        }
      }

      if (type == 'input') {
        return (
          // <RenderForm component={component} globalData={globalData} className={className} extra={extra} />
          <input className={className} {...camelCaseAttributes} />
        )
      }

      if (type == 'form') {
        return <Form className={className} component={component} />
      }

      if (type == 'button') {
        return (
          <button
            className={className}
            id={attributes?.id}
            {...camelCaseAttributes}
          >
            {children}
          </button>
        )
      }

      if (tagName == 'details') {
        const isOpen = className.includes('start_open')
        return (
          <details className={className} open={isOpen} id={attributes?.id}>
            {children}
          </details>
        )
      }
      if (tagName == 'summary') {
        return (
          <summary className={className} id={attributes?.id}>
            {children}
          </summary>
        )
      }

      if (tagName == 'ul') {
        return (
          <ul className={className} id={attributes?.id}>
            {children}
          </ul>
        )
      }

      if (tagName == 'ol') {
        return (
          <ol
            className={className}
            id={attributes?.id}
            {...camelCaseAttributes}
          >
            {children}
          </ol>
        )
      }

      if (tagName == 'li') {
        return (
          <li
            className={className}
            id={attributes?.id}
            {...camelCaseAttributes}
          >
            {children}
          </li>
        )
      }

      if (tagName == 'br') {
        return <br />
      }

      return (
        <div className={className} {...camelCaseAttributes}>
          {children}
        </div>
      )
    },
    [type, content, attributes, components]
  )

  return (
    <>
      {renderItem(
        (components || []).map((comp: HTMLBuilderComponent, index) => {
          return (
            <GrapeParser
              key={index}
              component={comp}
              currentItem={currentItem}
              globalData={globalData}
              data={data}
              extra={extra}
            />
          )
        })
      )}
    </>
  )
}

export default GrapeParser

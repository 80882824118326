import { randomCharacterGenerator } from '@/utils/random-character-generator'
import { createElement, FC, useCallback } from 'react'
import { AccordionContainer } from '../Accordion'
import CustomText from './CustomText'
import styles from './parse-view.module.css'
import NextImage from '../image'
import RenderImageList from './RenderImageList'

export type JSONContent = {
  type?: string
  attrs?: Record<string, any>
  content?: JSONContent[]
  marks?: {
    type: string
    attrs?: Record<string, any>
    [key: string]: any
  }[]
  text?: string
  [key: string]: any
}
interface IParserView {
  json: JSONContent
}

const ParserView: FC<IParserView> = props => {
  const {
    json: { type, content, attrs, text, marks },
  } = props

  const renderItem = useCallback<(children: React.ReactNode) => JSX.Element>(
    children => {
      if (type == 'doc') {
        return <div className="w-full prose">{children}</div>
      }
      if (type == 'blockquote') {
        return (
          <blockquote className="text-xl italic font-semibold text-gray-900">
            <svg
              aria-hidden="true"
              className="w-10 h-10 text-gray-700"
              viewBox="0 0 24 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                fill="currentColor"
              />
            </svg>
            <p>{children}</p>
          </blockquote>
        )
      }
      if (type == 'text') {
        return <CustomText text={text} marks={marks} />
      }
      if (type == 'paragraph') {
        return <p>{children}</p>
      }
      if (type == 'sectionImageOnly') {
        return <div className={attrs['class']}>{children}</div>
      }
      if (type == 'bulletList') {
        return <ul>{children}</ul>
      }
      if (type == 'orderedList') {
        return <ol>{children}</ol>
      }
      if (type == 'table') {
        return (
          <table>
            <tbody>{children}</tbody>
          </table>
        )
      }
      if (type == 'horizontalRule') {
        return <hr />
      }
      if (type == 'listItem') {
        return <li>{children}</li>
      }
      if (type == 'heading') {
        return createElement(`h${Number(attrs.level)}`, null, children)
      }
      if (type == 'tableRow') {
        return <tr>{children}</tr>
      }
      if (type == 'custom-id') {
        return <>{children}</>
      }
      if (type == 'custom-component') {
        return (
          attrs?.data?.image_list && (
            <RenderImageList imageList={attrs?.data?.image_list} />
          )
        )
      }
      if (type == 'layout') {
        return <div className={attrs['class']}>{children}</div>
      }
      if (type == 'image') {
        return (
          <figure className="w-full">
            <NextImage
              className="w-full"
              src={attrs['src']}
              alt={attrs['alt']}
              width="0"
              height="0"
              sizes="100vw"
            />
            {attrs['alt'] && <figcaption>{attrs['alt']}</figcaption>}
          </figure>
        )
      }

      if (type == 'tableCell') {
        return (
          <td
            colSpan={attrs['colspan']}
            rowSpan={attrs['rowspan']}
            width={attrs['colwidth']}
          >
            {children}
          </td>
        )
      }

      if (type == 'toggle-block') {
        return (
          <AccordionContainer>
            <div title={attrs['title']}>{children}</div>
          </AccordionContainer>
        )
      }
      if (type == 'embed') {
        return (
          <>
            <div className={styles.iframeContainer}>
              <iframe
                src={attrs['src']}
                className="absolute top-0 left-0 w-full h-full"
              />
            </div>
            <i className="text-sm">{attrs['title']}</i>
          </>
        )
      }
      return <>{type}</>
    },
    [attrs, marks, text, type]
  )

  return (
    <>
      {renderItem(
        content &&
          content.map(block => {
            return (
              <ParserView json={block} key={randomCharacterGenerator(10)} />
            )
          })
      )}
    </>
  )
}

export default ParserView

import { FC } from 'react'
import GrapeParser, { HTMLBuilderParserProps } from '.'

const DataCurrentItem: FC<HTMLBuilderParserProps> = props => {
  const { currentItem, component, className, globalData, extra } = props

  return (
    <div className={className} {...component.attributes}>
      {component.components?.map((component, index) => {
        return (
          <GrapeParser
            key={index}
            component={component}
            globalData={globalData}
            data={currentItem}
            extra={extra}
          />
        )
      })}
    </div>
  )
}

export default DataCurrentItem

import { get } from 'lodash'

/**
 * Get value from object by field.
 * Returns data if field is null.
 * Returns data if data is not an object or null.
 */
export function getValueByField(data: unknown, field: string | null) {
  if (!field || typeof data !== 'object' || data === null) {
    return data
  }

  return get(data, field, null)
}

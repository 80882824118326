import React, { cloneElement, FC } from 'react'
interface IMetaheader {
  metaHeaderItems: IMetaItem[]
}

import SvgsProps from '@/components/svgs/constant'
import dynamic from 'next/dynamic'

const BrandYoutube = dynamic(() => import('@/components/svgs/youtube'))
const BrandTripAdvisor = dynamic(() => import('@/components/svgs/tripadvisor'))
const BrandTiktok = dynamic(() => import('@/components/svgs/tiktok'))
const BrandLinkedin = dynamic(() => import('@/components/svgs/linkedin'))
const BrandWhatsapp = dynamic(() => import('@/components/svgs/whatsapp'))
const BrandFacebook = dynamic(() => import('@/components/svgs/facebook'))

export interface IMetaItem {
  name: string
  url: string
  icon: React.ReactElement<SvgsProps>
}

export const SOCIAL_ACCOUNTS: IMetaItem[] = [
  {
    icon: <BrandWhatsapp />,
    name: 'tiktok',
    url: 'https://wa.me/84349417856',
  },
  {
    icon: <BrandYoutube />,
    name: 'youtube',
    url: 'https://www.youtube.com/@mrbikersaigon9631',
  },
  {
    icon: <BrandTripAdvisor />,
    name: 'tripadvisor',
    url: 'https://www.tripadvisor.com/Attraction_Review-g293925-d13006978-Reviews-Mr_Biker_Saigon-Ho_Chi_Minh_City.html',
  },
  {
    icon: <BrandLinkedin />,
    name: 'linkedin',
    url: 'https://www.linkedin.com/company/mr-biker-saigon/',
  },
  {
    icon: <BrandTiktok />,
    name: 'tiktok',
    url: 'https://www.tiktok.com/@bikeshopsaigon?_t=8VQpOVzTVGU&_r=1',
  },
  {
    icon: <BrandFacebook />,
    name: 'tiktok',
    url: 'https://www.facebook.com/mrbikersaigon',
  },
]

const MetaHeader: FC<IMetaheader> = props => {
  const { metaHeaderItems } = props
  return (
    <>
      {metaHeaderItems.map((item, index) => (
        <li key={`meta-item-${index}`} className="header-meta-item w-6 h-auto">
          {item.url ? (
            <a
              className="nav-item hover:text-primaryDark"
              href={item.url}
              target="_blank"
              rel="noreferrer noopener"
              aria-label={item.name}
            >
              {cloneElement(item.icon)}
            </a>
          ) : (
            <>{item.name}</>
          )}
        </li>
      ))}
    </>
  )
}

export default MetaHeader

import React, {
  cloneElement,
  Dispatch,
  FC,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  useState,
} from 'react'
import { m, AnimatePresence } from 'framer-motion'
import dynamic from 'next/dynamic'
import { isArray } from 'lodash'

const CaretDown = dynamic(() => import('@/components/svgs/caret-down'))
interface AccordionProps {
  title: string
  itemIndex?: number
  expanded?: false | number
  setExpanded?: Dispatch<SetStateAction<number | false>>
}

export const Accordion: FC<PropsWithChildren<AccordionProps>> = ({
  itemIndex,
  expanded,
  setExpanded,
  children,
  title,
}) => {
  const isOpen = itemIndex === expanded

  return (
    <>
      <m.div
        initial={false}
        onClick={() => setExpanded(isOpen ? false : itemIndex)}
        className="accordion-header flex justify-between py-2 text-xl font-bold hover:text-primary cursor-pointer"
      >
        {title}
        {
          <m.span animate={{ rotate: isOpen ? 0 : -90 }}>
            <CaretDown />
          </m.span>
        }
      </m.div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <m.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            className="overflow-hidden"
            transition={{
              duration: 0.5,
              ease: [0.04, 0.62, 0.23, 0.98],
            }}
          >
            {children}
          </m.div>
        )}
      </AnimatePresence>
    </>
  )
}
interface AccordionContainerProps {
  children: ReactElement[] | ReactElement
}

export const AccordionContainer: FC<AccordionContainerProps> = props => {
  const { children } = props
  const [expanded, setExpanded] = useState<false | number>(0)

  if (!isArray(children))
    return (
      <Accordion
        key={children.props.title}
        title={children.props.title}
        expanded={expanded}
        setExpanded={setExpanded}
      >
        {cloneElement(children, children.props)}
      </Accordion>
    )

  return (
    <>
      {children.map((el, index) => (
        <Accordion
          key={el.props.title}
          title={el.props.title}
          itemIndex={index}
          expanded={expanded}
          setExpanded={setExpanded}
        >
          {cloneElement(el, el.props)}
        </Accordion>
      ))}
    </>
  )
}

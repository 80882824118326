import SideDetail from '@/components/sections/tour-detail/side-detail'
import { GRAPEJS_CUSTOM_BLOCKS } from '@/ts/constant'
import HeroImageSearch from '@/components/common/hero-image-search'
import GrapeParser, { HTMLBuilderParserProps } from '.'
import ToggleCurrencies from '@/components/Currencies/ToggleCurrencies'
import ShopSideBar from '@/components/ShopSidebar'
import PaymentCardMobile from '@/components/sections/tour-detail/payment-card-mobile'

function handleCustomComponent(props: HTMLBuilderParserProps) {
  const { component, extra, currentItem } = props
  const { attributes } = component

  if (attributes?.id == 'payment-card') {
    return extra.product && <SideDetail post={extra.product} />
  }

  if (attributes?.id == 'payment-card-mobile') {
    return extra.product && <PaymentCardMobile post={extra.product} />
  }

  if (attributes?.id == GRAPEJS_CUSTOM_BLOCKS.HeroImageSearch) {
    return (
      currentItem &&
      extra?.section_gallery && (
        <HeroImageSearch
          pageData={currentItem}
          section_gallery={extra.section_gallery}
        />
      )
    )
  }

  if (attributes?.id == GRAPEJS_CUSTOM_BLOCKS.WeTravelBookingButton) {
    return (
      <button
        className="wtrvl-checkout_button"
        id="wetravel_button_widget"
        data-env="https://www.wetravel.com"
        data-version="v0.3"
        data-uid="679131"
        data-uuid="82847724"
      >
        {component.components?.map((component, index) => {
          return (
            <GrapeParser
              key={index}
              component={component}
              currentItem={currentItem}
            />
          )
        })}
      </button>
    )
  }

  if (attributes?.id == GRAPEJS_CUSTOM_BLOCKS.ToggleCurrencies) {
    return <ToggleCurrencies />
  }

  if (attributes?.id == GRAPEJS_CUSTOM_BLOCKS.ShopSideBar) {
    return (
      <div className="shop-sidebar">
        <ShopSideBar />
      </div>
    )
  }

  return null
}

export default handleCustomComponent
